<template>
    <div style="userSelect:none;">
        <div class="app-name" style="width:180px;">
            <div class="title">
                商推助手管理平台
            </div>
        </div>
        <el-menu active-text-color="#ffd04b" background-color="#545c64" class="el-menu-vertical-demo"
            :default-active="store.activeSidebar" text-color="#fff" style="border:none;">
            <el-menu-item index="/goods" @click="toRoute('/goods')">
                <el-icon>
                    <Goods />
                </el-icon>
                <span>商品管理</span>
            </el-menu-item>
            <el-menu-item index="/vip" @click="toRoute('/vip')">
                <el-icon>
                    <Avatar />
                </el-icon>
                <span>会员管理</span>
            </el-menu-item>
            <el-menu-item index="/order" @click="toRoute('/order')">
                <el-icon>
                    <Memo />
                </el-icon>
                <span>订单管理</span>
            </el-menu-item>
            <el-menu-item index="/cash" @click="toRoute('/cash')">
                <el-icon>
                    <document />
                </el-icon>
                <span>提现管理</span>
            </el-menu-item>
            <el-menu-item index="/user" @click="toRoute('/user')">
                <el-icon>
                    <setting />
                </el-icon>
                <span>账号管理</span>
            </el-menu-item>
        </el-menu>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router';
import { useIndexStore } from "@/store/index";
export default defineComponent({
    name: '',
    props: {
    },
    components: {
    },
    setup(props, ctx) {
        const router = useRouter()
        const store = useIndexStore();
        const toRoute = (route) => {
            router.push({ path: route, replace: true })
        }
        return { toRoute,store }
    }
})
</script>
<style scoped>
.app-name {
    display: flex;
    justify-content: center;
    padding: 20px 0px;
    background-color: #444b52;
}

.title {
    font-size: 18px;
    color: #fff;
}
</style>
 