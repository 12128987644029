<template>
  <div>
    <div id="app">
      <router-view />
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useIndexStore } from "@/store/index";
import { localGet } from './utils';
export default {
  name: 'App',
  setup() {
    const router = useRouter()
    router.beforeEach((to, from, next) => {
      const token = localGet('token')
      const store = useIndexStore();
      if (to.path == '/login') {
        next()
      } else {
        // if(false){
        if (token == 'undefined' || token == '' || token == undefined || token == null) {
          next({ path: '/login' })
        } else {
          store.activeSidebar = to.path
          store.routeName = to.meta.title
          next()
        }
      }
      // document.title = pathMap[to.name]
    })
  }
}
</script>

<style>
.box-card {

max-height: calc(100vh - 200px);
overflow-y: auto;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
legend,
button,
input,
textarea,
th,
td {
  margin: 0;
  padding: 0;
}

body,
button,
input,
select,
textarea {
  font: 12px/1.5tahoma, arial, \5b8b\4f53;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}

address,
cite,
dfn,
em,
var {
  font-style: normal;
}

code,
kbd,
pre,
samp {
  font-family: couriernew, courier, monospace;
}

small {
  font-size: 12px;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

legend {
  color: #000;
}

fieldset,
img {
  border: 0;
}

button,
input,
select,
textarea {
  font-size: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
</style>
