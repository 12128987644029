<template>
    <div class="container">
        <sidebar class="navbar" style="width: 180px;"></sidebar>
        <div class="content">
            <navbar></navbar>
            <div style="max-width: calc(100vw - 200px);">
                <router-view />
            </div>
            <div class="footer">
                <span>
                    ICP备案编号: 
                </span>
                <a href="https://beian.miit.gov.cn/#/Integrated/index" style="color:#fff;padding-left: 10px;">豫ICP备案编号 2023013152号-1</a>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import sidebar from './components/sidebar.vue';
import navbar from './components/navbar.vue';
export default defineComponent({
    name: '',
    props: {
    },
    components: {
        sidebar,
        navbar
    },
    setup(props, ctx) {
        return {}
    }
})
</script>
<style scoped>
.container {
    width: 100vw;
    height: 100vh;
    min-width: 800px;
    min-height: 600px;
    display: flex;
}

.navbar {
    width: 180px;
    background-color: #545c64;
}

.content {
    flex-grow: 1;
}

.footer {
    width: 100%;
    height: 24px;
    line-height: 24px;
    position: fixed;
    bottom: 0;
    background-color: #303030;
    color: #fff;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
 