import axios from "axios";
import { localGet } from "@/utils";
import { ElMessageBox, ElMessage } from "element-plus";
import route from "@/route/index";

const baseURL = "https://www.sdsfc.com.cn/wx";
//创建axios实例
const service = axios.create({
  baseURL: baseURL,
  withCredentials: true,
  timeout: 320000,
  headers: {
    "Content-type": "application/json;charset=utf-8",
  },
});

//请求拦截
service.interceptors.request.use(
  (config) => {
    config.headers = config.headers || {};
    if (localGet("token")) {
      config.headers.token = localGet("token") || "";
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

//响应拦截
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    // console.log("interceptors", res)
    if (res.code !== 200) {
      if (res.code === 0) {
        route.push({ name: "Login" });
        ElMessage({
          message: res.message || "Error",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }
      ElMessage({
        message: res.message || "Error",
        type: "error",
        duration: 5 * 1000,
      });
      return Promise.reject(new Error(res.message || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    ElMessage({
      message: error.message || "error",
      type: "error",
      duration: 5 * 1000,
    });
    // return Promise.reject(error)
  }
);

export default service;
