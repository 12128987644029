<template>
    <div>
        <div class="container" style="userSelect:none">
            <div class="routeNmae">{{ store.routeName }}</div>
            <el-popover placement="bottom" :width="300" trigger="click" :popper-style="{ 'padding': '0' }">
                <template #reference>
                    <div class="userinfo">
                        <img src="../../assets/a.gif" alt="加载失败" class="userinfo-img">
                        <el-icon>
                            <ArrowDownBold />
                        </el-icon>
                    </div>
                </template>
                <div class="content">
                    <div class="userName">
                        <span>用户: </span>
                        <span>{{ store.username }}</span>
                    </div>
                    <div class="logout">
                        <el-button type="info" @click="handleLogOut">退出登录</el-button>
                    </div>
                </div>
            </el-popover>
        </div>
    </div>
</template>
<script>
import { defineComponent, watchEffect } from 'vue'
import { useIndexStore } from "@/store/index";
import { logOut } from '@/api/user';
import { localRemove } from '@/utils';
import { ElMessageBox } from 'element-plus';
import { useRouter } from 'vue-router';
export default defineComponent({
    name: '',
    props: {
    },
    components: {
    },
    setup(props, ctx) {
        const route = useRouter()
        const store = useIndexStore();
        const handleLogOut = () => {
            logOut({ username: store.username }).then((res) => {
                route.push({ path: '/login', replace: true })
                localRemove('token')
            }).catch()
        }
        return { store, handleLogOut }
    }
})
</script>
<style scoped>
.container {
    width: 100%;
    height: 60px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.userinfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
}

.userinfo-img {
    width: 50px;
    height: 50px;
    padding: 0 10px;
}

.content {
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(to right, #868f96 0%, #596164 100%);
}

.routeNmae {
    line-height: 60px;
    font-size: 22px;
    font-weight: 600;
    margin-left: 30px;
    letter-spacing: 4px;
}

.userName {
    color: #fff;
    font-size: 18px;
}
</style>

<style>
:v-deep .el-popper {
    padding: 0;
}
</style>
 