export function localGet (key) {
    const value = window.sessionStorage.getItem(key)
    try {
      return JSON.parse(window.sessionStorage.getItem(key))
    } catch (error) {
      return value
    }
  }
  
  export function localSet (key, value) {
    window.sessionStorage.setItem(key, JSON.stringify(value))
  }
  
  export function localRemove (key) {
    window.sessionStorage.removeItem(key)
  }
  