import { defineStore } from 'pinia'

export const useIndexStore = defineStore('index', {
  state: () => {
    return {
      routeName: "商品管理",
      activeSidebar: "/goods",
      username: '',
    };
  },
  persist: {
    enabled: true // true 表示开启持久化保存
  }
})