import service from "@/utils/request";

// 登录接口
export function login(data) {
    return service({
        url: '/back/login',
        method: "POST",
        data
    })
}

// 退出登录
export function logOut(data) {
    return service({
        url: '/back/auth/logOut',
        method: "POST",
        data
    })
}

// 获取用户列表
export function getInfo(data) {
    return service({
        url: '/back/auth/getInfo',
        method: "GET",
        data
    })
}

// 获取用户列表
export function addUser(data) {
    return service({
        url: '/back/auth/add',
        method: "POST",
        data
    })
}