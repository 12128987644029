import { createRouter, createWebHashHistory } from "vue-router";

import Layout from "@/layout";

export const constantRoutes = [
  {
    path: "/",
    redirect: "/login",
    component: () => import("@/pages/login"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/login",
    component: () => import("@/pages/login"),
    name: "Login",
  },
  {
    path: "/",
    component: Layout,
    redirect: "/goods",
    children: [
      {
        path: "goods",
        component: () => import("@/pages/goods/index.vue"),
        name: "Goods",
        meta: {
          title: "商品管理",
        },
      },
      {
        path: "vip",
        component: () => import("@/pages/vip/index.vue"),
        name: "Vip",
        meta: {
          title: "会员管理",
        },
      },
      {
        path: "order",
        component: () => import("@/pages/order/index.vue"),
        name: "Order",
        meta: {
          title: "订单管理",
        },
      },
      {
        path: "cash",
        component: () => import("@/pages/cash/index.vue"),
        name: "Cash",
        meta: {
          title: "提现管理",
        },
      },
      {
        path: "user",
        component: () => import("@/pages/user/index.vue"),
        name: "User",
        meta: {
          title: "账号管理",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes,
  scrollBehavior: () => ({
    y: 0,
  }),
});

export default router;
